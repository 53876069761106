<template lang="pug">
div.c-exchange
  div.pr-2.pl-2.c-exchange__item
    v-text-field(
      :value="examDate"
      @input="$emit('update:examDate', $event)"
      name="date"
      label="Exam date"
    )
  div.pr-2.pl-2.c-exchange__item
    filter-autocomplete(
      :value="location" 
      @input="$emit('update:location', $event)"
      :getItems="getExamLocation"
      label="Location" 
    )
</template>

<script>
import {mapActions} from "vuex"
export default {
  props: {
    examDate: {
      required: true
    },
    location: {
      required: true
    }
  },
  data: () => ({
    formData: {
      location: null,
      date: null
    }
  }),

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
    }),
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue')
  }
}
</script>

<style lang="scss">
.c-exchange {
  display: flex;
  border: 1px solid $primary-color;
  border-radius: 8px;
  padding: 0 5px;
  &__item {
    width: 50%;
  }

}
</style>